import styled from "styled-components";
import { constants } from "../../theme";

const LayoutWidthContainer = styled.div`
  width: 100%;
  padding: 0 16px;
  justify-self: center;
  margin-left: auto;
  margin-right: auto;
  max-width: calc(1400px + 2 * 16px);
  @media (min-width: ${constants.breakpoints.sm}) {
    padding: 0 40px 0 40px;
  }
`;

export default LayoutWidthContainer;