import * as React from "react"
import MainLayout from "../components/layout";
import Header from "../components/Header";
import About from "../components/About";
import Video from "../components/Video";
import Slider from "../components/Slider";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import BigQuote from "../components/BigQuote";

const IndexPage = () => {
  return (
    <MainLayout>
      <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      >
      
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="description" content="When given the choice, families choose charter schools. Find out why." />
      <title>Iowa Charter Schools</title>
      <link rel="canonical" href="https://www.iowacharters.org" />
      <meta property="og:url" content="https://www.iowacharters.org" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Iowa Charter Schools Are Growing" />
      <meta property="og:description" content="When given the choice, families choose charter schools. Find out why." />
      <meta property="og:image" content="https://www.iowacharters.org/social.jpg" />
      <meta property="og:image:alt" content="Iowa Charters Social Image" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Iowa Charter Schools Are Growing" />
      <meta name="twitter:description" content="When given the choice, families choose charter schools. Find out why." />
      <meta name="twitter:image" content="https://www.iowacharters.org/social.jpg" />
      </Helmet>
      <Header />
      <About />
      <BigQuote text="22 of the top 100 public high schools in America are charter schools." citation="US News and World Report, 2022 Rankings" />
      <Slider />
      <Video />

      <Footer />
    </MainLayout>
  )
}

export default IndexPage
