import * as React from "react";
import styled from "styled-components";
import LayoutWidthContainer from "../shared/LayoutWidthContainer";
import FlexWrapper from "../shared/FlexWrapper";
import { constants } from "../../theme";
import { HRuleLarge } from "../shared/Rules";

const Wrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 80px;
    grid-row-gap: 80px; 
    padding-top: 100px;
    padding-bottom: 2rem;

    .text { grid-area: 1 / 1 / 2 / 3; }
    .video { grid-area: 2 / 1 / 3 / 3; }

    @media(min-width: ${constants.breakpoints.lg}) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
               
        .text { grid-area: 1 / 1 / 2 / 2; }
        .video { grid-area: 1 / 2 / 2 / 3; }
            
    }

    @media(min-width: ${constants.breakpoints.xl}) {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 1fr;

        .text { grid-area: 1 / 1 / 2 / 3; }
        .video { grid-area: 1 / 3 / 2 / 6; } 
    }
`;

const Headline = styled.h1`
    font-family: forma-djr-display, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 52px;
    color: #000000;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;

    @media(min-width: ${constants.breakpoints.md}) {
        text-align: left;
    }
`;


const Paragraph = styled.p`
    font-family: forma-djr-text, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
    text-align: center;

    @media(min-width: ${constants.breakpoints.md}) {
        text-align: left;
    }
`;

const VideoWrapper = styled.div`
    width: 100%;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;


const Video = () => (
    <LayoutWidthContainer>
        <Wrapper>
            <FlexWrapper className="text">
                <div>
                    <Headline>Charter schools work in communities everywhere.</Headline>
                    <HRuleLarge />
                    <Paragraph>
                    Though much attention is placed on charter schools in urban settings, they aren't the only places where public charter schools can offer students a high-quality education. Take a look at how rural charter schools are effectively meeting the needs of the students in their communities.
                    </Paragraph>
                </div>
            </FlexWrapper>
            <FlexWrapper className="video">
                <VideoWrapper>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/QNFQK4r6TCI?controls=0" title="The story of rural charter schools" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </VideoWrapper>
            </FlexWrapper>
        </Wrapper>
    </LayoutWidthContainer>
    
)

export default Video;