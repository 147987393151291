import * as React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import CssReset from "./CssReset";
import Constants from "./Constants";

export const constants = Constants;

const theme = {

}

const ThemeProvider = ({children}) => {
    return (
    <StyledThemeProvider theme={theme}>
        <CssReset />
        { children }
    </StyledThemeProvider>
    )

}

export default ThemeProvider;