import * as React from "react";
import styled from "styled-components";
import ThemeProvider, { constants } from "../../theme";

const LayoutGrid = styled.main`
    min-height: 100vh;
    min-width: 100%;
    display: grid;
    grid-template-columns: 100%;
    gap: 80px;
    align-content: space-between;
    @media (max-width: ${constants.breakpoints.sm}) {
        gap: 40px;
    }
`;

const MainLayout = ({ children }) => (
    <ThemeProvider>
        <LayoutGrid>
            { children }
        </LayoutGrid>
    </ThemeProvider>
);

export default MainLayout;