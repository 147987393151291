import styled from "styled-components";
import { constants } from "../../theme";

export const HRuleLarge = styled.hr`
    width: 75%;
    max-width: 230px;
    margin: 32px auto 38px;
    border: 0;
    border-top: 9px solid #DC3832;

    @media (min-width: ${constants.breakpoints.md}) {
        margin: 32px 0 38px;
    }
`;

export const HRuleSmall = styled.hr`
    width: 75%;
    max-width: 145px;
    margin: 16px 0 16px;
    border: 0;
    border-top: 9px solid #DC3832;
`;

export const HRule = styled.hr`
    width: 25%;
    max-width: 200px;
    margin: 16px 0 24px;
    border: 0;
    border-top: 3px solid #5F0772;
`;