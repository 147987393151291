import React, {useEffect} from "react";
import styled from "styled-components";
import { constants } from "../../theme";
import { useFormik } from "formik";
import { useState } from "react";


const FormFieldWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    @media (min-width: ${constants.breakpoints.sm}) {
        flex-direction: row;
        max-width:600px;
        margin-left: auto;
        margin-right: auto;
    }
    @media (min-width: ${constants.breakpoints.lg}) {
        margin: 0;
    }
    &.errors {
        padding: 8px 0;
        font-family: forma-djr-text, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: red;
    }
`;

const InputEmail = styled.input`
    display: block;
    width: 100%;
    padding: 8px 14px;
    font-family: forma-djr-text, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
    background-color: #FFFFFF;
    background-clip: padding-box;
    border: 1px solid #000000;
    appearance: none;
    border-radius: 8px;
    margin-right: 8px;

    &:focus {
        color: #000000;
        background-color: #FFFFFF;
        border-color: #000000;
        outline: 0;
    }

    &::placeholder {
        color: #000000;
        opacity: 0.25;
    }

    &:disabled,
    &.disabled,
    &[readonly] {
        color: #777777;
        background-color: #E3E3E3;
        border-color: #000000;
        opacity: 1;
    }
`;

const MainButton = styled.button`
    border-radius: 0;
    display: inline-block;
    box-sizing: border-box;
    background: #DC3832;
    border: 1px solid #000000;
    border-radius: 8px;
    padding: 8px 14px;
    font-family: forma-djr-text, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;
    white-space: nowrap;
    user-select:none;

    &:hover {
        color: #FFFFFF;
        background: #B22D29;
    }

    &.disabled {
        color: #EEEEEE;
        background: #CCCCCC;
    }
`;
const Form = styled.form`
    &.hidden {
        display:none;
    }
`;

const Success = styled.h4`
    display: none;
    font-family: forma-djr-display, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    text-align: center;
    margin:16px; 
    @media (min-width: ${constants.breakpoints.lg}) {
        text-align: left;
    }
    @media (min-width: ${constants.breakpoints.lg}) {
        text-align: center;
    }
    &.show {
        display:block;
    }
`;

const FormWrapper = styled.div`
        width: 100%;
        box-sizing: border-box;
        padding: 4px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.75);
        max-width: 420px;
        margin: 0 auto;

        @media (max-width: calc(${constants.breakpoints.xl} - 1px)) and (orientation: landscape) {
            margin: 0;
            padding: 0;
            background: transparent;
        }

    `;

const ContactForm = () => {
    const [formComplete, setFormComplete] = useState(false);

    useEffect(() => {
        if(typeof window !==`undefined`){
            const params = new URLSearchParams(window.location.search);
            const formParam = params.get("formComplete");
          if(formParam){
            setFormComplete(true);
          }
        }
      }, []);

    const handleSubmit = async () => {
        try {
            const data = { formData: formik.values };
            const response = await fetch('./.netlify/functions/contact', {
              method: 'POST',
              body: JSON.stringify(data)});
            const body = await response.json();
    
            if (response.status === 200 || response.statusText === "OK") {
                if (window !== undefined) {
                    window.dataLayer.push({
                        event: "sign_up",
                        method: "website",
                    });
                }
                if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
                    /* global fbq */
                    fbq('track', 'Lead', {content_name: 'IAContactForm'});
                }
                setFormComplete(true)
                formik.resetForm();
                formik.status = null
            } else {
                if (window !== undefined) {
                    window.dataLayer.push({
                        event: 'error',
                        error: body.err.message
                    });
                }
                formik.setStatus(body.err.message);
                formik.setSubmitting(false);
            }
        } catch (error) {
            if (window !== undefined) {
                window.dataLayer.push({
                    event: 'error',
                    error: error.message
                });
            }

            setTimeout(() => {
                formik.errors.email = error.message;
                formik.setStatus("There was an error processing your request.");
                formik.setSubmitting(false);
            }, 1000);
        }
       
      }
 
    const formik = useFormik({
        initialValues: {
          email: "",
        },
        validate: (data) => {
          let errors = {};
  
          if (!data.email) {
              errors.email = 'Email is required.';
          }
          else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
              errors.email = 'Please enter a valid email address.';
          }

          return errors;
        },
        onSubmit: (data) => {
          handleSubmit() 
          
        }
      })

      


    return (
        <FormWrapper>           
            <Form className={ formComplete ? "hidden" : ""}  onSubmit={formik.handleSubmit} autoComplete="on">
                <FormFieldWrapper>
                    <InputEmail className={formik.isSubmitting ? "disabled" : ""} type="email" name="email" placeholder="Email Address" value={formik.values.email} onChange={formik.handleChange} required autoComplete="email" />
                    <MainButton className={formik.isSubmitting ? "disabled" : ""} type="submit">{formik.isSubmitting ? `Submitting` : `Learn More`}</MainButton>
                </FormFieldWrapper>
                {formik.errors.email && formik.touched.email ? (
                    <FormFieldWrapper className="errors">
                        <div>{formik.status && `${formik.status} Please try again.`}</div>
                    </FormFieldWrapper>
                ): null}
            </Form>
            <Success className={ formComplete ? "show" : ""}>Thank you for signing up!</Success> 
            
        </FormWrapper>

    )
}

export default ContactForm;