import * as React from "react";
import { A11y } from 'swiper';
import { useState } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper";
import { constants } from "../../theme";
import { HRuleSmall } from "../shared/Rules";
import "swiper/css";
import "swiper/css/effect-fade";
import "./styles.css"


const SlideWrapper = styled.div`
    display: flex;
    justify-content: center;
    background: #F0F2F1;
    width: 100%;
    height: 100%;
`;

const SlideContainer = styled.div`
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    @media(min-width: ${constants.breakpoints.md}){
        flex-direction: row;
    }
`;

const SlideQuestion = styled.div`
    background: #3F0458;
    padding: 20px;
    width: 100%;
    position: relative;

    @media(min-width: ${constants.breakpoints.sm}){
        padding: 80px 20px;
    }
    @media(min-width: ${constants.breakpoints.md}){
        width: 400px;
        min-width: 400px;
    }
    @media(min-width: ${constants.breakpoints.lg}){
        padding: 80px;
        width: 40vw;
        min-width: 40vw;
    }
`;

const QuestionWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;
`;

const SubHeading = styled.div`
    display: inline-block;
    font-family: forma-djr-display, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 20px;
    letter-spacing: 2px; 

    @media(min-width: ${constants.breakpoints.lg}){
        margin-bottom: 32px;
    }
`;

const Question = styled.h4`
font-family: forma-djr-display, sans-serif;
font-style: normal;
font-weight: 900;
font-size: 48px;
line-height: 58px;
margin-bottom: 32px;
color: #FFFFFF;
`;

const SlideAnswer = styled.div`
    flex-grow:1;
    padding: 20px;
    @media(min-width: ${constants.breakpoints.sm}){
        padding: 80px 40px;
    }
    @media(min-width: ${constants.breakpoints.lg}){
        padding: 80px;
    }
`;

const Answer = styled.h5`
    font-family: forma-djr-text, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    margin-bottom: 32px;
`;

const Paragraph = styled.p`
    font-family: forma-djr-text, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
    margin-bottom: 32px;
`;

const NavLink = styled.button`
    font-family: forma-djr-display, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    letter-spacing: 4px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
    display: inline-block;
    box-sizing: border-box;
    text-decoration: none;
    white-space: nowrap;
    user-select:none;
    background: transparent;
    border: 0;
    text-transform: uppercase;
`;

const HRule = styled.hr`
    display: inline-block;
    width: 35px;
    max-width: 200px;
    margin: 0 8px 5px;
    border: 0;
    border-top: 3px solid #9A0CB2;
`;

const HRuleLong = styled.hr`
    width: 45%;
    max-width: 200px;
    margin: 7px 0 5px;
    border: 0;
    border-top: 3px solid #9A0CB2;
    float: right;
`;

const RedBar = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 230px;
    background: #DC3832;
`;

const ArticleLink = styled.button`
    font-family: forma-djr-display, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    letter-spacing: 1px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
    display: inline-block;
    box-sizing: border-box;
    text-decoration: none;
    user-select:none;
    background: transparent;
    border: 0;
`;

const Navigation = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    >div {
        max-width: 50%;
    }
    .prev-nav {
        padding-right: 10px;

        ${ArticleLink}, ${NavLink} {
            float:left;
            text-align: left;
        }
    }
    .next-nav {
        padding-left: 10px;

        ${ArticleLink}, ${NavLink} {
            float: right;
            text-align: right;
        }
    }
`;


const MainButton = styled.a`
    font-family: forma-djr-display, sans-serif;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
    color: #6A087B;



`;


const Slider = () => {
    const [swiper, setSwiper] = useState(null);


    function nextSlide() {
        if(swiper){
            swiper.slideNext()
            if (window !== undefined) {
                window.dataLayer.push({
                    'event': 'slide_next',
                    'slide': (swiper.realIndex + 1).toString()
                })
            }
        }
    }

    function prevSlide() {
        if(swiper){
            swiper.slidePrev()
            if (window !== undefined) {
                window.dataLayer.push({
                    'event': 'slide_prev',
                    'slide': (swiper.realIndex + 1).toString()
                })
            }
        }
    }

    function swiperInit(swiper) {
        setSwiper(swiper);
    }

    const slides = [
        {
            question: 'Do you have to pay to go to a public charter school?',
            answer: 'No, charter schools are always tuition-free, public schools.',
            paragraph: 'Charter schools, like all public schools, are tuition-free. However, it is important to understand why students do not have to pay to receive a high-quality education at a charter school. As public schools, charter schools are publicly funded by local, state, and federal tax dollars similar to district—or traditional—schools. This funding ensures that charter schools are free, public, and open to all.',
            articleLink: 'https://www.publiccharters.org/latest-news/2019/02/14/do-you-have-pay-go-charter-school',
            articleText: 'Learn more about tuition-free public charter schools',
            prevTitle: false,
            nextTitle: 'What is a charter school lottery?',
        },
        {
            question: 'What is a public charter school lottery?',
            answer: 'When charter schools have more students who want to attend than the seats available, they use a lottery system to determine their enrollment.',
            paragraph: 'The charter school must hold a random drawing process, either handling the applications manually, or using a computer program.',
            articleLink: 'https://www.publiccharters.org/latest-news/2019/02/07/what-charter-school-lottery',
            articleText: 'Learn more about charter school lotteries',
            prevTitle: 'Do you have to pay to go to a public charter school?',
            nextTitle: 'Are charter schools public schools?',

        },
        {
            question: 'Are charter schools public schools?',
            answer: 'Yes, charter schools are always independently-operated, public schools that are open to all students.',
            paragraph: 'Charter schools accept any student who applies to them, provided there is room. Charter schools are in high demand, so to play fair they have a lottery system that gives everyone an equal opportunity to get in. The best way to make sure all students have access to a high-quality public school that is right for them is to ensure that charter schools exist and are allowed to thrive.',
            articleLink: 'https://www.publiccharters.org/latest-news/2022/02/24/are-charter-schools-public-schools',
            articleText: 'Learn more about charter public schools',
            prevTitle: 'What is a public charter school lottery?',
            nextTitle: 'Can anyone go to a charter school?',
        },
        {
            question: 'Can anyone go to a charter school?',
            answer: 'Yes, charter schools are open to all students.',
            paragraph: 'They do not charge tuition or have admissions tests, making them accessible for all families. Like all public schools, anyone can go to a charter schools regardless of socio-economic background. In fact, charter schools serve a higher percentage of students from low-income backgrounds (60%) than district-run public schools (53%). Charter schools cannot discriminate against students based on their household incomes, race, gender, or academic backgrounds.',
            articleLink: 'https://www.publiccharters.org/latest-news/2019/01/08/can-anyone-go-charter-school',
            articleText: 'Learn more about charter school admissions',
            prevTitle: 'Are charter schools public schools?',
            nextTitle: 'Are charter schools for-profit organizations??',
        },
        {
            question: 'Are charters schools for-profit?',
            answer: 'No. Charter schools are always public schools, but some of the confusion is about how the schools are managed.',
            paragraph: 'Most charter schools – 65% – handle everything themselves. About 26% rely on a nonprofit organization to help with things like payroll, human resources and building management. About 9% rely on a for-profit management company to help with things like this.',
            articleLink: 'https://www.publiccharters.org/latest-news/2019/01/16/are-charter-schools-profit',
            articleText: 'Learn more about how charter schools are managed',
            prevTitle: 'Can anyone go to a charter school?',
            nextTitle: 'Can public charter schools deny students?',
        },
        {
            question: 'Can public charter schools deny students?',
            answer: 'No, charter schools enroll students on a first-come, first-served basis and additional students are placed on a wait list.',
            paragraph: "Regardless of a family's household income, religion, or ethnicity, charter schools exist to provide all students with a local high-quality school option. For many families, charter schools are a great fit. They provide children with another free, high-quality public school option.",
            articleLink: 'https://www.publiccharters.org/latest-news/2019/02/01/can-charter-schools-deny-students',
            articleText: 'Learn more about charter school admissions',
            prevTitle: 'Are charter schools for-profit organizations?',
            nextTitle: 'Do public charter schools take the same tests?',
        },
        {
            question: 'Do charter school students take the same state tests?',
            answer: 'Yes, Iowa charter school students must take state tests, just like any public school students.',
            paragraph: "Iowa charter schools are subject to the same state academic standards and testing requirements. Charter schools, by law, must assess all public school students in reading and math.",
            articleLink: 'https://www.publiccharters.org/latest-news/2019/03/05/do-charter-schools-have-take-state-tests',
            articleText: 'Learn more about charter school testing',
            prevTitle: 'Can public charter schools deny students?',
            nextTitle: false,
        },
    ]

    return (
        <div>
        <Swiper
            modules={[A11y, EffectFade]}
            spaceBetween={0}
            slidesPerView={1}
            effect={"fade"}
            className="factSwiper"
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onSwiper={(swiper) => swiperInit(swiper)}
            >
            {slides.map((slide, index) => 
            <SwiperSlide key={index}>
                <SlideWrapper>
                    <SlideContainer>
                        <SlideQuestion>
                            <RedBar />
                            <QuestionWrapper>
                                <div>
                                    <SubHeading>Question {index + 1} of {slides.length}</SubHeading>
                                    <HRuleLong />
                                    <Question>{slide.question}</Question>
                                </div>
                                <Navigation>
                                    {slide.prevTitle ? <div className="prev-nav">
                                        <NavLink aria-label="Previous Slide" onClick={() => nextSlide()}>Prev<HRule/></NavLink><br />
                                        <ArticleLink aria-label={slide.prevTitle} onClick={() => prevSlide()}>{slide.prevTitle}</ArticleLink>
                                    </div> : <div className="prev-nav"></div>}
                                
                                    {slide.nextTitle ? <div className="next-nav">
                                        <NavLink aria-label="Next Slide" onClick={() => nextSlide()}><HRule/>Next</NavLink><br />
                                        <ArticleLink aria-label={slide.nextTitle} onClick={() => nextSlide()}>{slide.nextTitle}</ArticleLink>
                                    </div> : <div className="next-nav"></div>}

                                </Navigation>
                            </QuestionWrapper>
                        </SlideQuestion>
                        <SlideAnswer>
                            <Answer>{slide.answer}</Answer>
                            <Paragraph>{slide.paragraph}</Paragraph>
                            <HRuleSmall />
                            <MainButton href={slide.articleLink} target="_blank">{ slide.articleText }</MainButton>
                        </SlideAnswer>
                    </SlideContainer>
                </SlideWrapper>
            </SwiperSlide>
        )}
        </Swiper>
        </div>
    )
}


export default Slider;
