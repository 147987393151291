import * as React from "react";
import styled from "styled-components";
import { constants } from "../../theme";

const Wrapper = styled.div`
    width: 100%;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
`;

const Headline = styled.h4`
    font-family: forma-djr-display, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin-bottom: 24px;
    margin-top: 0;
    text-align: center;

    @media (min-width: ${constants.breakpoints.md}) {
        font-size: 32px;
        line-height: 38px;
    }
`;

const Paragraph = styled.p`
    font-family: forma-djr-display, sans-serif;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 16px;
    line-height: 24px;
    color: #5F0772;
    text-transform: uppercase;
    text-align: center;
`;


const BigQuote = ({ text, citation }) => (

    <Wrapper>
        <Headline>{ text }</Headline>
        <Paragraph>{ citation }</Paragraph>
    </Wrapper>

);

export default BigQuote;