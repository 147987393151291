import * as React from "react";
import styled from "styled-components";
import LayoutWidthContainer from "../shared/LayoutWidthContainer";
import { constants } from "../../theme";
import { HRuleLarge, HRule } from "../shared/Rules";

const Section = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
`;

const Wrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;

    grid-column-gap: 80px;
    grid-row-gap: 80px; 
    padding-bottom: 4vw;

    @media (min-width: ${constants.breakpoints.lg}) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        .card { 
            min-height: 40vh;
        }
        .card-1-1 { grid-area: 1 / 1 / 2 / 2; }
        .card-1-2 { grid-area: 1 / 2 / 2 / 3; }
        .card-2-1 { grid-area: 2 / 1 / 3 / 2; }
        .card-2-2 { grid-area: 2 / 2 / 3 / 3; }
    }
`


const Headline = styled.h1`
    font-family: forma-djr-display, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 45px;
    color: #000000;
    text-align: center;

    @media (min-width: ${constants.breakpoints.md}) {
        font-size: 48px;
        line-height: 52px;
        text-align: left;
    }
`;

const SubHeading = styled.div`
    font-family: forma-djr-display, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1px;
    color: #5F0772;
    margin-bottom: 8px;
`;

const Heading = styled.h4`
    font-family: forma-djr-display, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;
    color: #000000;
    margin-bottom: 8px;
    
`;

const Paragraph = styled.p`
    font-family: forma-djr-text, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
`;





const About = () => (
    <Section>
        <LayoutWidthContainer>
            <Wrapper>
                
                <div className="card-1-1">
                    <div>
                        <Headline>
                            When given the choice familes choose charter schools.
                        </Headline>
                        <HRuleLarge />
                    </div>
                </div>
  
                <div className="card-1-2">
                    <div>
                        <SubHeading>The Charter School Model</SubHeading>
                        <Heading>Free. Public. Open to all.</Heading>
                        <HRule />
                        <Paragraph>
                        Charter schools are tuition-free public schools that accept all students and families who choose to enroll in them. In many cases, they are founded by people within the community who want to create options for families whose children may need something different.
                        </Paragraph>
                    </div>
                </div>
            
                
                <div className="card-2-1">
                    <div>
                        <SubHeading>The Charter School Environment</SubHeading>
                        <Heading>Charter schools are built around students</Heading>
                        <HRule />
                        <Paragraph>
                        Because every charter school is founded for a different reason, they are all unique. Some charter schools have science, technology, engineering, and mathematics (STEM) curriculum or an agricultural focus, others use a Montessori learning model or a college-prep focus, and some are dual credit—offering college credit while in high school, and still others provide career training.
                        </Paragraph>
                    </div>
                </div>
                <div className="card-2-2">
                    <div>
                        <SubHeading>The Charter School Opportunity</SubHeading>
                        <Heading>Charter schools can educate anyone</Heading>
                        <HRule />
                        <Paragraph>
                        Charter schools can serve children in any grade, from Pre-K to 12th, and some offer other programs like GED courses or classes for adults who didn’t have the opportunity to graduate high school earlier.
                        </Paragraph>
                    </div>
                </div>
            </Wrapper>
        </LayoutWidthContainer>
    </Section>
    
)

export default About;